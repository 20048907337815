<template>
  <patient-profile
    title="Create Patient Profile"
    method="add"
    :changed.sync="changed"
  ></patient-profile>
</template>

<script>
import PatientProfile from '@/components/patients/PatientProfile.vue'

export default {
  components: { PatientProfile },
  data() {
    return {
      changed: false,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.changed && to.name !== 'login' && to.name !== 'view-patient') {
      this.$root.confirm({
        title: 'Discard?',
        body: 'Are you sure you wish to cancel creating this patient profile',
        cancel: 'No',
        confirm: 'Discard',
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          next()
        }
      })
    } else {
      next()
    }
  },
}
</script>
